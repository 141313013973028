import { faFileDownload, faSave } from '@fortawesome/pro-regular-svg-icons';
import swal from '@sweetalert/with-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import reqwest from 'reqwest';
import settings from '../../../../../../settings';
import Button from '../../../../../components/Button';
import Editor from '../../../../../components/Editor';
import Form from '../../../../../components/Form';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import HeaderbarButton from '../../../../../components/HeaderbarButton';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Input from '../../../../../components/Inputs/Input';
import Switch from '../../../../../components/Inputs/Switch';
import Loader from '../../../../../components/Loader';
import DataContainer from '../../../../../logic/dataContainer';
import getConstants from '../../../../../logic/constants';

class Print extends Component {
  hourValidator = [
    {
      custom: (value) => {
        return value >= 0;
      },
      error: 'Ungültig',
    },
    {
      custom: (value) => {
        return value < 101;
      },
      error: 'Maximal 100 Stunden',
    },
  ];

  constructor(props) {
    super(props);

    this.clientGuid = props.match.params.clientGuid;
    this.clientName = props.match.params.clientName;
    this.supplyNetworkType = props.match.params.type;
    this.supplyNetworkGuid = props.match.params.guid;
    this.psychName = props.match.params.psychName;

    this.state = {
      assigning: false,
      clientSaving: false,
      comment: '',
      documentBlob: null,
      documentBlobPreview: null,
      selectedFromType: 'a',
      readOnly: true,
      pageNumber: 1,
      pageValueOne: '',
      pageValueTwo: '',
      pageValueThree: '',
      editTextsWasOpened: false,
      editTexts: false,
      loading: false,
      CarePsych: [],
      CarePsychIsPsychotherapist: [],
    };

    this.formType = [
      {
        value: 'a',
        label: 'Formular A: „Beratung und Indikationsstellung“',
        kkz: 'a',
      },
      {
        value: 'b',
        label: 'Formular B: „Beratung und Verlängerung“',
        kkz: 'b',
      },
      {
        value: 'c',
        label: 'Formular C: „Beratung und psychologische Begleitung“',
        kkz: 'c',
      },
      {
        value: 'd',
        label:
          'Formular D: „Beratung und Wechsel der Therapeutin oder des Therapeuten“',
        kkz: 'd',
      },
    ];
    this.dataContainer = new DataContainer();
    this.dataContainer.add('TherapyPlaceAssignment', {
      ClientGuid: this.clientGuid,
      SupplynetworkGuid: this.supplyNetworkGuid,
      TB_VERFAHRENSWECHSEL: '',
      TB_PSYCHIATRIE: '',
      FREITEXT_DIAGNOSE: '',
      THERAPIEUMFANG1: 0,
      THERAPIEUMFANG2: 0,
      FORM_TYPE: 'a',
      Reference: '',
      Clerk: '',
      CareTherapist: '',
    });
  }

  async componentDidMount() {
    try {
      await this.getData();
      await this.pdfPreview();
      await this.getUserList();
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.selectedFromType !== this.state.selectedFromType) {
      this.getData();
    }
  }

  getData = async () => {
    this.setState({
      loading: true,
    });
    return new Promise((resolve, reject) => {
      reqwest({
        method: 'post',
        url: settings.clientmanagement.client.assignTherapyPlaceTexts,
        data: {
          TherapyPlaceAssignment: this.dataContainer.getStringified(),
          action: `Therapieplatz zugewiesen. Kommentar: ${this.state.comment}`,
        },
        session: JSON.parse(localStorage.getItem('userdata')),
      })
        .then((res) => {
          this.formPagesContainer = new DataContainer(res);
          this.setState({
            pageValueOne: this.formPagesContainer.get(
              'AssignTherapyPlacePages',
              'PAGE1',
            ),
            pageValueTwo: this.formPagesContainer.get(
              'AssignTherapyPlacePages',
              'PAGE2',
            ),
            pageValueThree: this.formPagesContainer.get(
              'AssignTherapyPlacePages',
              'PAGE3',
            ),
            loading: false,
          });
          resolve();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          reject(error);
        });
    });
  };

  pdfPreview = async () => {
    if (!this.dataContainer) {
      return Promise.reject(new Error('DataContainer not found'));
    }

    return new Promise((resolve, reject) => {
      this.setState({
        loading: true,
      });
      const success = (blob) => {
        this.setState({
          documentBlobPreview: blob,
          loading: false,
        });
        resolve();
      };

      try {
        this.formPagesContainer.set(
          'AssignTherapyPlacePages',
          'PAGE1',
        )(this.state.pageValueOne);
        this.formPagesContainer.set(
          'AssignTherapyPlacePages',
          'PAGE2',
        )(this.state.pageValueTwo);
        this.formPagesContainer.set(
          'AssignTherapyPlacePages',
          'PAGE3',
        )(this.state.pageValueThree);

        var xhr = new XMLHttpRequest();
        xhr.open(
          'POST',
          settings.clientmanagement.client.assignTherapyPlaceFileReview,
          true,
        );
        xhr.responseType = 'blob';
        xhr.onload = function (_) {
          if (this.status == 200) {
            var blob = new File([this.response], 'Assignment.pdf', {
              type: 'application/pdf',
            });
            success(blob);
          } else {
            const err = {
              status: this.status,
              statusText: this.statusText,
              responseText: this.responseText,
            };
            swal({
              title: 'Therapieplatz zuweisen nicht möglich!',
              text: `Es ist ein Fehler beim zuweisen des Therapieplatzes aufgetreten. (${err.status} ${err.statusText} ${err.responseText})`,
              icon: '',
            });
            this.setState({
              documentBlobPreview: null,
            });
            reject(err);
          }
        };
        let data = new FormData();
        data.append(
          'TherapyPlaceAssignment',
          this.dataContainer.getStringified(),
        );
        data.append('dcTexts', this.formPagesContainer.getStringified());

        xhr.send(data);
      } catch (error) {
        console.log('error', error);
        this.setState({
          documentBlobPreview: null,
          loading: false,
        });
        reject(error);
      }
    });
  };

  async getUserList() {
    const CarePsych = await getConstants('CarePsych');
    const CarePsychIsPsychotherapist = await CarePsych.filter((cp) => {
      return cp.IsPsychotherapist === true;
    });

    this.setState({
      CarePsych,
      CarePsychIsPsychotherapist:
        CarePsychIsPsychotherapist.length > 0
          ? CarePsychIsPsychotherapist
          : [
              {
                value: 'false',
                label: 'Es gibt keinen CARE-Psychotherapeut/in',
              },
            ],
    });
  }
  assign = async () => {
    swal({
      title: 'Möchten Sie wirklich den Therapieplatz zuweisen?',
      buttons: {
        back: 'Nein',
        confirm: 'Ja',
      },
    }).then((confirm) => {
      if (!confirm) {
        return;
      }
      this.setState({
        assigning: true,
      });
      const success = (blob) => {
        swal({
          title: 'Therapieplatz zugewiesen!',
          text: 'Therapieplatz erfolgreich zugewiesen. Das Dokument kann jetzt gedruckt werden.',
          icon: 'success',
        });
        this.setState({ documentBlob: blob, assigning: false });
      };
      try {
        this.formPagesContainer.set(
          'AssignTherapyPlacePages',
          'PAGE1',
        )(this.state.pageValueOne);
        this.formPagesContainer.set(
          'AssignTherapyPlacePages',
          'PAGE2',
        )(this.state.pageValueTwo);
        this.formPagesContainer.set(
          'AssignTherapyPlacePages',
          'PAGE3',
        )(this.state.pageValueThree);
        var xhr = new XMLHttpRequest();
        xhr.open(
          'POST',
          settings.clientmanagement.client.assignTherapyPlaceFile,
          true,
        );
        xhr.responseType = 'blob';
        xhr.onload = function (_) {
          if (this.status == 200) {
            var blob = new File([this.response], 'Assignment.pdf', {
              type: 'application/pdf',
            });
            success(blob);
          } else {
            swal({
              title: 'Therapieplatz zuweisen nicht möglich!',
              text: `Es ist ein Fehler beim zuweisen des Therapieplatzes aufgetreten. (${err.status} ${err.statusText} ${err.responseText})`,
              icon: '',
            });
            this.setState({
              assigning: false,
            });
          }
        };
        let data = new FormData();
        data.append(
          'TherapyPlaceAssignment',
          this.dataContainer.getStringified(),
        );
        data.append('dcTexts', this.formPagesContainer.getStringified());
        data.append(
          'action',
          `Therapieplatz zugewiesen.  Kommentar: ${this.state.comment}`,
        );
        data.append('historyType', 'Client');
        xhr.send(data);
      } catch (error) {
        this.setState({
          assigning: false,
        });
      }
    });
  };

  download = () => {
    if (this.state.documentBlob) {
      const formName = this.formType.find((ft) => {
        return (
          ft.value ===
          this.dataContainer.get('TherapyPlaceAssignment', 'FORM_TYPE')
        );
      }).label;
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(this.state.documentBlob);
      link.download = `${formName}.pdf`;
      link.click();
      link.remove();
    } else {
      swal({
        title: 'Dokument nicht verfügbar',
        text: 'Das hat leider nicht funktioniert!',
        icon: 'error',
      });
    }
  };

  done = () => {
    return swal({
      title: 'Fertig?',
      text: 'Bitte stellen sie sicher, dass alle Dokumente erfolgreich gedruckt wurden. Das Dokument wird NICHT im System gespeichert und kann deshalb nicht erneut aufgerufen werden.',
      buttons: {
        back: 'zurück',
        confirm: 'Fertig!',
      },
    }).then((value) => {
      if (value) {
        this.props.history.replace(
          '/clientmanagement/detail/' + this.clientGuid,
        );
      } else {
        return;
      }
    });
  };

  save = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Fehler bei Ihren Eingaben',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }

    const oneDataContainer = JSON.stringify({
      ParamName: 'Client',
      Params: this.dataContainer.get('Entry'),
    });

    reqwest({
      method: 'POST',
      url: settings.clientmanagement.dataContainer.save,
      data: {
        container: oneDataContainer,
        action: 'Klient/in bearbeitet',
      },
    })
      .then(() => {
        swal({
          title: 'Gespeichert!',
          text: 'Erfolgreich gespeichert.',
          icon: 'success',
        });
      })
      .catch(() => {
        swal({
          title: 'Speichern nicht möglich!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
      });
  };

  handleChange = (value) => {
    this.setState((prevState) => {
      if (prevState.pageNumber === 1) {
        return { pageValueOne: value };
      } else if (prevState.pageNumber === 2) {
        return { pageValueTwo: value };
      } else if (prevState.pageNumber === 3) {
        return { pageValueThree: value };
      }
    });
  };

  addChangeToPDF = async () => {
    swal({
      icon: this.state.editTextsWasOpened ? 'warning' : 'info',
      title: 'Änderungen in PDF übernehmen',
      text: this.state.editTextsWasOpened
        ? 'Alle Anpassungen aus dem Editor gehen verloren'
        : 'Möchten Sie die Änderungen in das PDF übernehmen?',
      buttons: {
        back: 'Nein',
        confirm: 'Ja',
      },
    })
      .then(async (confirm) => {
        if (!confirm) return;
        try {
          /**
           * !!Don't remove this comment
           * @description about the process:
           * The getData function is the only function that can change the data on the pages text
           * so we need to call it first to get the current data from the server
           * then we can call the pdfPreview function to get the new pdf with the new data
           */
          await this.getData();
          await this.pdfPreview();
          this.setState({ editTextsWasOpened: false });
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  render() {
    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement"> Klientenverwaltung </Link>
            {' - '}
            <Link to={`/clientmanagement/detail/${this.clientGuid}`}>
              Klient/in &quot;{this.clientName}&quot;
            </Link>
            {' - '}
            <Link
              to={`/clientmanagement/detail/selectCounselling/SelectAmbulatoryPsychotherapyOverview/${this.clientGuid}/${this.clientName}`}
            >
              Therapeut/in auswählen oder suchen
            </Link>
            {' - '}
            &quot;{this.psychName}&quot;
            {' - '}
            <b>Formularservice</b>
          </div>
          <div className="Headerbar-buttons">
            <HeaderbarButton
              type="primary"
              icon={faSave}
              onClick={this.assign}
              loading={this.state.assigning}
            >
              Therapieplatz zuweisen & Dokument erstellen
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          {!this.state.documentBlob ? (
            <Form
              ref={(node) => {
                return (this.form = node);
              }}
            >
              <div className="Split-Panes">
                <div className="Split-Pane">
                  <ContentBox title="Formular wählen:">
                    <Dropdown
                      label="Formular:"
                      getSelected={(value) => {
                        this.dataContainer.set(
                          'TherapyPlaceAssignment',
                          'FORM_TYPE',
                        )(value);
                        this.setState({
                          selectedFromType: value,
                        });
                      }}
                      defaultValue={this.dataContainer.get(
                        'TherapyPlaceAssignment',
                        'FORM_TYPE',
                      )}
                      options={this.formType}
                      validator={[
                        {
                          required: true,
                          error: 'Bitte Formular auswählen',
                        },
                      ]}
                      name="FORM_TYPE"
                      isDisabled={this.state.editTexts}
                    />
                    <Input
                      key="Aktenzeichen"
                      type="text"
                      isDisabled={this.state.editTexts}
                      validator={[]}
                      onChange={(value) => {
                        this.dataContainer.set(
                          'TherapyPlaceAssignment',
                          'Reference',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'TherapyPlaceAssignment',
                        'Reference',
                      )}
                      label="Aktenzeichen:"
                      name="reference"
                    />
                  </ContentBox>
                  <ContentBox title="Anmerkungen:">
                    <Dropdown
                      label="Sachbearbeitung:"
                      getSelected={(value) => {
                        this.dataContainer.set(
                          'TherapyPlaceAssignment',
                          'Clerk',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'TherapyPlaceAssignment',
                        'Clerk',
                      )}
                      options={this.state.CarePsych}
                      name="Clerk"
                      isDisabled={this.state.editTexts}
                    />
                    <Dropdown
                      label="CARE-Psychotherapeut/in:"
                      getSelected={(value) => {
                        this.dataContainer.set(
                          'TherapyPlaceAssignment',
                          'CareTherapist',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'TherapyPlaceAssignment',
                        'CareTherapist',
                      )}
                      options={this.state.CarePsychIsPsychotherapist}
                      name="CareTherapist"
                      isDisabled={this.state.editTexts}
                    />
                    {this.state.selectedFromType === 'a' && [
                      <Switch
                        key="TB_VERFAHRENSWECHSEL"
                        label="Die bisherige Psychotherapie wurde beendet, da sich herausgestellt hat, dass ein anderes Therapieverfahren eine deutlich höhere Erfolgsaussicht haben wird."
                        onChange={(value) => {
                          if (value === true) {
                            this.dataContainer.set(
                              'TherapyPlaceAssignment',
                              'TB_VERFAHRENSWECHSEL',
                            )(
                              'Die bisherige Psychotherapie wurde beendet, da sich herausgestellt hat, dass ein anderes Therapieverfahren eine deutlich höhere Erfolgsaussicht haben wird.',
                            );
                          } else {
                            this.dataContainer.set(
                              'TherapyPlaceAssignment',
                              'TB_VERFAHRENSWECHSEL',
                            )('');
                          }
                        }}
                        name="TB_VERFAHRENSWECHSEL"
                        defaultChecked={
                          !!this.dataContainer.get(
                            'TherapyPlaceAssignment',
                            'TB_VERFAHRENSWECHSEL',
                          )
                        }
                        isDisabled={this.state.editTexts}
                        validator={[]}
                      />,
                      <Switch
                        key="TB_PSYCHIATRIE"
                        label="Zudem wird eine ambulante psychiatrische Mitbehandlung empfohlen."
                        onChange={(value) => {
                          if (value === true) {
                            this.dataContainer.set(
                              'TherapyPlaceAssignment',
                              'TB_PSYCHIATRIE',
                            )(
                              'Zudem wird eine ambulante psychiatrische Mitbehandlung empfohlen.',
                            );
                          } else {
                            this.dataContainer.set(
                              'TherapyPlaceAssignment',
                              'TB_PSYCHIATRIE',
                            )('');
                          }
                        }}
                        name="TB_PSYCHIATRIE"
                        defaultChecked={
                          !!this.dataContainer.get(
                            'TherapyPlaceAssignment',
                            'TB_PSYCHIATRIE',
                          )
                        }
                        isDisabled={this.state.editTexts}
                        validator={[]}
                      />,
                      <Input
                        key="FREITEXT_DIAGNOSE"
                        type="textarea"
                        isDisabled={this.state.editTexts}
                        validator={[]}
                        onChange={(value) => {
                          this.dataContainer.set(
                            'TherapyPlaceAssignment',
                            'FREITEXT_DIAGNOSE',
                          )(value);
                        }}
                        defaultValue={this.dataContainer.get(
                          'TherapyPlaceAssignment',
                          'FREITEXT_DIAGNOSE',
                        )}
                        label="Bemerkung im Dokument:"
                        name="commentDocument"
                      />,
                    ]}
                    <Input
                      type="number"
                      validator={this.hourValidator}
                      isDisabled={this.state.editTexts}
                      onChange={(value) => {
                        this.dataContainer.set(
                          'TherapyPlaceAssignment',
                          'THERAPIEUMFANG1',
                        )(value);
                      }}
                      defaultValue={this.dataContainer.get(
                        'TherapyPlaceAssignment',
                        'THERAPIEUMFANG1',
                      )}
                      label="Anzahl der Therapiestunden (1)"
                      name="THERAPIEUMFANG1"
                    />
                    {this.state.selectedFromType === 'd' && (
                      <Input
                        type="number"
                        isDisabled={this.state.editTexts}
                        validator={this.hourValidator}
                        onChange={(value) => {
                          this.dataContainer.set(
                            'TherapyPlaceAssignment',
                            'THERAPIEUMFANG2',
                          )(value);
                        }}
                        defaultValue={this.dataContainer.get(
                          'TherapyPlaceAssignment',
                          'THERAPIEUMFANG2',
                        )}
                        label="Anzahl der Therapiestunden (2)"
                        name="THERAPIEUMFANG2"
                      />
                    )}
                    <span className="FormArea-ContentBox-title" />
                    <span className="Input-wrapper spaceer-dummy"></span>
                    <Button
                      type="primary"
                      icon={faSave}
                      onClick={this.addChangeToPDF}
                      disabled={this.state.editTexts}
                    >
                      Änderungen in PDF übernehmen
                    </Button>
                  </ContentBox>
                </div>
                <div className="Split-Pane">
                  <ContentBox title="Vorschau / Bearbeiten">
                    <Switch
                      checked={this.state.editTexts}
                      label="Bearbeiten oder Vorschau wählen:"
                      labelActive="Formular Texte bearbeiten"
                      labelInactive="Formular Vorschau"
                      onChange={() =>
                        this.setState((prev) => {
                          if (prev.editTexts) {
                            if (!this.state.editTextsWasOpened) {
                              this.setState({
                                editTextsWasOpened: true,
                              });
                            }
                          }
                          return {
                            editTexts: !prev.editTexts,
                          };
                        })
                      }
                    />

                    <div className="Input-wrapper spaceer-dummy"></div>
                    {!this.state.editTexts ? (
                      <>
                        {this.state.loading && <Loader />}
                        {!this.state.loading &&
                          this.state.documentBlobPreview && (
                            <object
                              data={window.URL.createObjectURL(
                                this.state.documentBlobPreview,
                              )}
                              type="application/pdf"
                              width="100%"
                              height="800"
                            >
                              <p>
                                Alternative text - include a link{' '}
                                <a
                                  href={window.URL.createObjectURL(
                                    this.state.documentBlobPreview,
                                  )}
                                >
                                  to the PDF!
                                </a>
                              </p>
                            </object>
                          )}
                      </>
                    ) : (
                      <>
                        <div className="tab Input--fullWidth">
                          <button
                            className={`tablinks ${
                              this.state.pageNumber === 1 && 'active'
                            }`}
                            onClick={() => this.setState({ pageNumber: 1 })}
                          >
                            Seite 1
                          </button>
                          <button
                            className={`tablinks ${
                              this.state.pageNumber === 2 && 'active'
                            }`}
                            onClick={() => this.setState({ pageNumber: 2 })}
                          >
                            Seite 2
                          </button>
                          <button
                            className={`tablinks ${
                              this.state.pageNumber === 3 && 'active'
                            }`}
                            onClick={() => this.setState({ pageNumber: 3 })}
                          >
                            Seite 3
                          </button>
                        </div>
                        {!this.state.loading ? (
                          <>
                            {this.state.pageNumber === 1 && (
                              <Editor
                                id="body"
                                onChange={(value) => this.handleChange(value)}
                                editable
                                updateConfig={(options) => ({
                                  ...options,
                                  zIndex: '0',
                                })}
                              >
                                {this.state.pageValueOne}
                              </Editor>
                            )}
                            {this.state.pageNumber === 2 && (
                              <Editor
                                id="body"
                                onChange={(value) => this.handleChange(value)}
                                editable
                              >
                                {this.state.pageValueTwo}
                              </Editor>
                            )}
                            {this.state.pageNumber === 3 && (
                              <Editor
                                id="body"
                                onChange={(value) => this.handleChange(value)}
                                editable
                              >
                                {this.state.pageValueThree}
                              </Editor>
                            )}
                          </>
                        ) : (
                          <Loader />
                        )}
                      </>
                    )}
                  </ContentBox>
                </div>
              </div>
            </Form>
          ) : (
            <div>
              <ContentBox title="Dokument drucken">
                <Button outline icon={faFileDownload} onClick={this.download}>
                  {
                    this.formType.find(
                      (ft) =>
                        ft.value ===
                        this.dataContainer.get(
                          'TherapyPlaceAssignment',
                          'FORM_TYPE',
                        ),
                    ).label
                  }
                  {' speichern'}
                </Button>
                <Button outline onClick={this.done}>
                  Fertig!
                </Button>
              </ContentBox>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Print;
