import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';
import Form from '../../../../../components/Form';
import ContentBox from '../../../../../components/FormArea/ContentBox';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import Loader from '../../../../../components/Loader';
import DataContainer from '../../../../../logic/dataContainer';
import reqwest from 'reqwest';
import settings from '../../../../../../settings';
import getConstants from '../../../../../logic/constants';

class PrintCaseFile extends Component {
  constructor(props) {
    super(props);

    this.clientGuid = props.match.params.clientGuid;

    this.state = {
      loading: true,
      generatingPDF: false,
      pdfBlob: null,
      PlaceOfStorage: null,
      PlaceOfStorageConstants: [],
    };
  }

  componentDidMount() {
    this.initConstants();
    this.fetchClient();
  }

  async initConstants() {
    this.setState({
      PlaceOfStorageConstants: await getConstants('PlaceOfStorage'),
    });
  }

  async fetchClient() {
    const data = { guid: this.clientGuid };
    if (this.logHistory === 'log') {
      data.isExtern = true; // todo: check if still needed
      data.action = 'Klient/in Auskunft an Klient/in erteilt';
    }

    reqwest({
      method: 'get',
      url: settings.clientmanagement.dataContainer.get,
      data: data,
    })
      .then((res) => {
        this.dataContainer = new DataContainer(res);
        this.setState({
          loading: false,
        });
        this.generateCaseFile();
      })
      .catch((err) => {
        swal({
          title: 'Fehler.',
          icon: 'error',
          text: `Beim initialisieren der Seite ist ein Fehler aufgetreten. (${err.status} ${err.statusText} ${err.responseText})`,
        });
        this.setState({
          loading: false,
        });
        this.props.history.goBack();
      });
  }

  async generateCaseFile() {
    this.setState({ generatingPDF: true });

    const success = (blob) => {
      this.setState({ pdfBlob: blob, generatingPDF: false });
    };
    const error = () => {
      swal({
        title: 'Nicht möglich.',
        icon: 'error',
        text: `Beim erstellen der Fallakte ist ein Fehler afugetretrten.`,
      });
      history.back();
    };

    var xhr = new XMLHttpRequest();
    xhr.open('POST', settings.clientmanagement.client.createCaseFile, true);
    xhr.responseType = 'blob';
    xhr.onload = function (_) {
      if (this.status == 200) {
        var blob = new File([this.response], 'Assignment.pdf', {
          type: 'application/pdf',
        });
        success(blob);
      } else {
        error();
      }
    };
    xhr.onerror = function (_) {
      error();
    };
    let data = new FormData();
    data.append('clientGuid', this.clientGuid);
    data.append('action', 'Fallakte erstellt');
    data.append('historyType', 'Client');
    xhr.send(data);
  }

  confirmPrint = async () => {
    const isValid = await this.form.validate();
    if (!isValid) {
      swal({
        title: 'Aufbewahrungsort wählen!',
        icon: 'error',
        text: 'Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      });
      return;
    }
    return swal({
      title: 'Druck bestätigen!',
      text: 'Durch das bestätigen des Drucks wird das Beratungsanliegen aus Care-Datenbank gelöscht und kann nicht wiederhergestellt werden.',
      buttons: {
        back: 'zurück',
        confirm: 'bestätigen',
      },
    }).then((value) => {
      if (value === true) {
        return this.confirmPrintUpdateClient();
      } else {
        return;
      }
    });
  };

  /**
   * Update Client
   * - removes CounselingConcern from Client
   * - sets PlaceOfStorage
   */
  confirmPrintUpdateClient = () => {
    this.dataContainer.set('Entry', 'CounselingConcern')('');
    this.dataContainer.set(
      'Entry',
      'PlaceOfStorage',
    )(this.state.PlaceOfStorage);

    const oneDataContainer = JSON.stringify({
      ParamName: 'Client',
      Params: this.dataContainer.get('Entry'),
    });

    reqwest({
      method: 'POST',
      url: settings.clientmanagement.dataContainer.save,
      data: {
        container: oneDataContainer,
        action: 'Klient/in bearbeitet',
      },
    })
      .then(() => {
        swal({
          title: 'Druck bestätigt!',
          text: 'Druck wurde erfolgreich bestätigt und das Beratungsanliegen aus der Care-Datenbank gelöscht.',
          icon: 'success',
        });
        this.props.history.goBack();
      })
      .catch(() => {
        swal({
          title: 'Fehler!',
          text: 'Es ist ein Fehler beim speichern aufgetreten',
          icon: '',
        });
      });
  };

  openPrint = () => {
    if (this.state.pdfBlob) {
      var fileURL = URL.createObjectURL(this.state.pdfBlob);
      var printWindow = window.open(fileURL, '');
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        printWindow.print();
      }
    } else {
      swal({
        title: 'PDF nicht verfügbar',
        text: 'Das hat leider nicht funktioniert!',
        icon: 'error',
      });
    }
  };

  render() {
    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">
            <Link to="/clientmanagement">Klientenverwaltung</Link> -{' '}
            <Link to={`/clientmanagement/detail/${this.clientGuid}`}>
              Klient/in
            </Link>{' '}
            -{' '}
            <b>
              {this.state.generated ? 'Fallakte drucken' : 'Fallakte erstellen'}
            </b>
          </div>
          <div className="Headerbar-buttons"></div>
        </div>
        {this.state.loading || (this.generatingPDF && <Loader />)}
        {!this.state.loading && !this.generatingPDF && (
          <div className="Page-Content">
            <Form
              ref={(node) => {
                return (this.form = node);
              }}
            >
              <div className="Split-Panes">
                <div className="Split-Pane">
                  <ContentBox title="Fallakte">
                    <Button icon={faFilePdf} onClick={this.openPrint}>
                      PDF Drucken
                    </Button>
                  </ContentBox>
                </div>
                <div className="Split-Pane">
                  <ContentBox title="Aufbewahrung">
                    <Dropdown
                      label="Aufbewahrungsort:"
                      getSelected={(value) => {
                        this.setState({ PlaceOfStorage: value });
                      }}
                      defaultValue={this.dataContainer.get(
                        'Entry',
                        'PlaceOfStorage',
                      )}
                      options={this.state.PlaceOfStorageConstants}
                      validator={[
                        {
                          required: true,
                          error: '',
                        },
                      ]}
                      name="template"
                    />
                    <Button onClick={this.confirmPrint}>
                      Druck bestätigen
                    </Button>
                  </ContentBox>
                </div>
              </div>
            </Form>
          </div>
        )}
      </>
    );
  }
}

export default PrintCaseFile;
