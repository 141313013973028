import reqwest from 'reqwest';
import settings from '../../settings';
import DataContainer from './dataContainer';

const constants = {};
const getConstants = async (type) => {
  if (!type) {
    return Promise.reject(
      'Constants: "getConstants" needs to have one argument, while you provided none.',
    );
  }
  if (!constants.hasOwnProperty(type)) {
    if (type === 'CarePsych') {
      constants[type] = await requestCarePsych();
    } else if (type === 'Roles') {
      constants[type] = await UserRoles();
    } else {
      constants[type] = await requestConstant(type);
    }
  }
  return constants[type];
};

async function requestConstant(type) {
  return reqwest({
    method: 'get',
    url: settings.constants.getList,
    data: {
      Token: localStorage.getItem('token'),
      typ: type,
    },
  }).then((typeResult) => {
    const result = JSON.parse(typeResult);
    const sortedMapped = result
      .map((element) => {
        return {
          value: element.Guid,
          label: element.Name,
          kkz: element.KKZ,
          order: parseInt(element.Ord),
        };
      })
      .sort((a, b) => {
        return a.order - b.order;
      });

    return sortedMapped;
  });
}

async function requestCarePsych() {
  return reqwest({
    method: 'get',
    url: settings.usermanagement.general.getList,
    data: {
      Token: localStorage.getItem('token'),
    },
  }).then((typeResult) => {
    let result = JSON.parse(typeResult);
    result = result.userList
      .filter((cp) => {
        return !cp.MarkingAs && cp.MarkingAs !== 'false';
      })
      .sort((a, b) => {
        if (a.Ord > b.Ord) return 1;
        if (a.Ord < b.Ord) return -1;
        return 0;
      })
      .map((element) => {
        return {
          value: element.Guid,
          label: element.Firstname + ' ' + element.Lastname,
          IsPsychotherapist: element.IsPsychotherapist
            ? element.IsPsychotherapist
            : false,
        };
      });

    return result;
  });
}

async function UserRoles() {
  return reqwest({
    method: 'get',
    url: settings.permissions.GetAllRoles,
    contentType: 'json',
    data: {
      Token: localStorage.getItem('token'),
    },
  }).then((res) => {
    const rolesDataContainer = new DataContainer(res);
    return rolesDataContainer.getMany('Entry').map((role) => {
      return {
        value: role.Guid,
        label: role.Name,
      };
    });
  });
}
export default getConstants;
