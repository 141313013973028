import moment from 'moment';
import React, { Component } from 'react';
import getConstants from '../logic/constants';

const AmbPsychFilterContext = React.createContext();

class AmbPsychFilterProvider extends Component {
  // the context state
  state = {
    supplyNetworkOverviewData: [],
    selectAmbulatoryPsychotherapyOverviewData: [],
    filterData: {
      Gender: '',
      BirthYear: '',
      JobTitle: '',
      TherapyProcedures: '',
      BarrierFreeAccess: '',
      CashRegisterApproval: '',
      ReimbursementProcedure: '',
      OfferVideoTherapy: '',
      AgilTraining: '',
      ApprovalForGroupPsychotherapy: '',
      Treatments: '',
      Comment: '',
      Distance: '',
      DisordersMainFocus: '',
      Volltextsuche: '',
    },
    CashRegisterApprovalsConstants: [],
    toggleFilters: true,
    sorting: { id: 'Lastname', desc: false },
  };

  // Run functions
  componentDidMount() {
    try {
      const filterData = JSON.parse(localStorage.getItem('filterData'));
      if (filterData) {
        this.setState({
          filterData,
        });
      }
    } catch (e) {
      localStorage.setItem('filterData', JSON.stringify(this.state.filterData));
      // no filterData in local storage
      console.warn('no filterData (for AmbPsychFilter) in local storage!');
    }
    try {
      const supplyNetworkOverviewData = JSON.parse(
        localStorage.getItem('supplyNetworkOverviewData'),
      );
      if (supplyNetworkOverviewData) {
        this.setState({
          supplyNetworkOverviewData,
        });
        console.log('Log from AmbPsychFilterContext');
      }
    } catch (e) {
      localStorage.setItem(
        'supplyNetworkOverviewData',
        JSON.stringify(this.state.supplyNetworkOverviewData),
      );
      // no supplyNetworkOverviewData in local storage
      console.warn(
        'no supplyNetworkOverviewData (for AmbPsychFilter) in local storage!',
      );
    }
    try {
      const selectAmbulatoryPsychotherapyOverviewData = JSON.parse(
        localStorage.getItem('selectAmbulatoryPsychotherapyOverviewData'),
      );
      if (selectAmbulatoryPsychotherapyOverviewData) {
        this.setState({
          selectAmbulatoryPsychotherapyOverviewData,
        });
        console.log('Log from AmbPsychFilterContext');
      }
    } catch (e) {
      localStorage.setItem(
        'selectAmbulatoryPsychotherapyOverviewData',
        JSON.stringify(this.state.selectAmbulatoryPsychotherapyOverviewData),
      );
      // no supplyNetworkOverviewData in local storage
      console.warn(
        'no selectAmbulatoryPsychotherapyOverviewData (for AmbPsychFilter) in local storage!',
      );
    }
    // filter state
    try {
      const showFilters = localStorage.getItem('showFilters');

      this.setState({
        showFilters: showFilters,
      });
    } catch (e) {
      localStorage.setItem('showFilters', true);
      this.setState({
        showFilters: true,
      });
    }
    // sorted state
    try {
      const sorting = JSON.parse(localStorage.getItem('sorting'));
      if (sorting) {
        this.setState({
          sorting,
        });
      }
    } catch (e) {
      localStorage.setItem(
        'sorting',
        JSON.stringify({ id: 'Lastname', desc: false }),
      );
      this.setState({
        sorting: { id: 'Lastname', desc: false },
      });
    }
    this.initConstants();
  }

  async initConstants() {
    this.setState({
      CashRegisterApprovalsConstants: await getConstants(
        'CashRegisterApproval',
      ),
    });
  }
  // Method to update filter open state
  toggleFilters = () => {
    this.setState(
      (prevState) => {
        return { toggleFilters: !prevState.toggleFilters };
      },
      () => {
        localStorage.setItem(
          'showFilters',
          JSON.stringify(this.state.filterData),
        );
      },
    );
  };

  saveSorting = (sorted) => {
    this.setState({ sorting: sorted }, () => {
      localStorage.setItem('sorting', JSON.stringify(sorted));
    });
  };

  // Method to update state
  saveFilterData = (key, value) => {
    this.setState(
      (prevState) => {
        return {
          filterData: {
            ...prevState.filterData,
            [key]: value === null ? '' : value,
          },
        };
      },
      () => {
        localStorage.setItem(
          'filterData',
          JSON.stringify(this.state.filterData),
        );
      },
    );
  };

  // Method to update state
  clearFilterData = () => {
    this.setState({
      filterData: {
        Gender: '',
        BirthYear: '',
        JobTitle: '',
        TherapyProcedures: '',
        BarrierFreeAccess: '',
        CashRegisterApproval: '',
        ReimbursementProcedure: '',
        OfferVideoTherapy: '',
        AgilTraining: '',
        ApprovalForGroupPsychotherapy: '',
        Treatments: '',
        Comment: '',
        Distance: '',
        DisordersMainFocus: '',
        Volltextsuche: '',
        city: '',
        searchLatLonValue: '',
      },
    });
    localStorage.removeItem('filterData');
  };

  translateFilters = (key) => {
    switch (key) {
      case 'Gender':
        return 'Geschlecht';
      case 'BirthYear':
        return 'Alter';
      case 'JobTitle':
        return 'Berufsbezeichnung';
      case 'TherapyProcedures':
        return 'Therapieverfahren';
      case 'BarrierFreeAccess':
        return 'BarrierFreeAccess';
      case 'CashRegisterApproval':
        return 'Kassenzulassung';
      case 'ApprovalForGroupPsychotherapy':
        return 'Zulassung zur Gruppentherapie';
      case 'AgilTraining':
        return 'AGIL';
      case 'Treatments':
        return 'Treatments';
      case 'DisordersMainFocus':
        return 'Störungsbilder Schwerpunkte';
      case 'CashRegisterApproval':
        return 'Kassenzulassung';
      case 'ReimbursementProcedure':
        return 'Kostenerstattungsverfahren';
      case 'OfferVideoTherapy':
        return 'Videotherapie';
      case 'Distance':
        return 'Distanz';
      default:
        return '';
    }
  };

  filterAndSortData = (data) => {
    const filters = this.state.filterData;
    const isDistanceFilterApplied = filters.Distance.length > 0;
    const noCashRegisterApproval =
      this.state.CashRegisterApprovalsConstants.find((item) => {
        if (item?.lable === 'keine' || item?.kkz === 'keine') return item.value;
      });

    // Count the number of active filter keys
    let numberOfActiveKeys = Object.keys(filters).filter((key) => {
      return (
        filters[key] !== '' &&
        key !== 'Volltextsuche' &&
        key !== 'city' &&
        key !== 'Distance' &&
        key !== 'searchLatLonValue'
      );
    }).length;

    // Map over the data to filter and score each row.
    const filteredAndScoredData = data
      .map((row) => {
        let points = 0;
        let matchedKeys = [];
        let unmatchedKeys = [];

        for (const key in filters) {
          if (filters[key] !== '') {
            switch (key) {
              case 'TherapyProcedures':
              case 'Treatments':
              case 'DisordersMainFocus':
                if (
                  !Array.isArray(row[key]) || // Check if row[key] is an array
                  !row[key].some((value) => {
                    return filters[key].includes(value);
                  })
                ) {
                  unmatchedKeys.push(this.translateFilters(key));
                } else {
                  points += Math.round(100 / numberOfActiveKeys);
                  matchedKeys.push(this.translateFilters(key));
                }
                break;
              case 'BirthYear':
                const diff = moment(row.BirthYear, 'YYYY').diff(
                  moment().subtract(filters.BirthYear, 'years'),
                  'years',
                );
                if (Math.abs(diff) < 5) {
                  points += Math.round(100 / numberOfActiveKeys);
                  matchedKeys.push(this.translateFilters(key));
                } else {
                  unmatchedKeys.push(this.translateFilters(key));
                }
                break;
              case 'Distance':
                if (
                  isDistanceFilterApplied &&
                  row.Distance <= filters.Distance
                ) {
                  return { ...row, points, matchedKeys, unmatchedKeys };
                } else if (!isDistanceFilterApplied) {
                  return { ...row, points, matchedKeys, unmatchedKeys };
                }
                break;
              case 'CashRegisterApproval':
                if (
                  filters[key] !== false &&
                  ['no', '', 'none', noCashRegisterApproval?.value].indexOf(
                    row.CashRegisterApproval,
                  ) === -1
                ) {
                  points += Math.round(100 / numberOfActiveKeys);
                  matchedKeys.push(this.translateFilters(key));
                } else if (
                  !filters[key] &&
                  ['no', '', 'none', noCashRegisterApproval?.value].indexOf(
                    row.CashRegisterApproval,
                  ) > -1
                ) {
                  points += Math.round(100 / numberOfActiveKeys);
                  matchedKeys.push(this.translateFilters(key));
                } else {
                  unmatchedKeys.push(this.translateFilters(key));
                }
                break;
              default:
                if (
                  !isDistanceFilterApplied ||
                  row.Distance <= filters.Distance
                ) {
                  if (row[key] === filters[key]) {
                    points += Math.round(100 / numberOfActiveKeys);
                    matchedKeys.push(this.translateFilters(key));
                  } else {
                    unmatchedKeys.push(this.translateFilters(key));
                  }
                }
            }
          }
        }

        // Return a new object with the original row data and the calculated points.
        if (points === 99) {
          points++;
        }
        if (
          (!isDistanceFilterApplied || points > 0) &&
          (!isDistanceFilterApplied || row.Distance <= filters.Distance)
        ) {
          return { ...row, points, matchedKeys, unmatchedKeys };
        }
      })
      .filter(Boolean); // Remove undefined entries

    // Sort the filtered and scored data
    const sortedData = filteredAndScoredData.sort((a, b) => {
      return b.points - a.points;
    });
    return sortedData;
  };

  setData = (className, data) => {
    this.setState({ [className]: data });
    localStorage.setItem(className, JSON.stringify(data));
  };

  filterRows = (data) => {
    // Split by space and comma
    const searchTerms =
      this.state.filterData.Volltextsuche.toLowerCase().split(/[\s,]+/);
    return data.filter((rowData) => {
      return [
        rowData.BirthYear,
        rowData.Lastname,
        rowData.Firstname,
        rowData.Street,
        rowData.ZIP,
        rowData.City,
        rowData.PhoneNumber1,
        rowData.PhoneNumber2,
        rowData.PhoneNumber3,
        rowData.PhoneNumber4,
        rowData.MailToCare,
        rowData.Comment,
      ]
        .map((e) => {
          return e ? e.toLowerCase() : e;
        })
        .some((v) => {
          return v
            ? searchTerms.some((term) => v.indexOf(term.trim()) >= 0)
            : false;
        });
    });
  };

  render() {
    const { children } = this.props;
    const {
      filterData,
      toggleFilters: showFilters,
      sorting,
      supplyNetworkOverviewData,
      selectAmbulatoryPsychotherapyOverviewData,
    } = this.state;
    const {
      saveFilterData,
      filterAndSortData,
      clearFilterData,
      toggleFilters,
      saveSorting,
      setData,
      filterRows,
    } = this;

    return (
      <AmbPsychFilterContext.Provider
        value={{
          sorting,
          saveSorting,
          showFilters,
          toggleFilters,
          filterData,
          filterAndSortData,
          saveFilterData,
          clearFilterData,
          setData,
          supplyNetworkOverviewData,
          selectAmbulatoryPsychotherapyOverviewData,
          filterRows,
        }}
      >
        {children}
      </AmbPsychFilterContext.Provider>
    );
  }
}

export default AmbPsychFilterContext;

export { AmbPsychFilterProvider };
