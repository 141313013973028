import {
  faEnvelopeOpenText,
  faFilter,
  faPlus,
  faRepeat,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ReactTable from 'react-table';
import settings from '../../../settings';
import Button from '../../components/Button';
import HeaderbarButton from '../../components/HeaderbarButton';
import Loader from '../../components/Loader';
import Searchbar from '../../components/Searchbar';
import SearchDropdown from '../../components/SearchDropdown';
import history from '../../history';
import api from '../../logic/api';
import getConstants from '../../logic/constants';

class Usermanagement extends Component {
  constructor(props) {
    super(props);

    let searchString = '';
    if (props.match.params.search) {
      searchString = props.match.params.search;
    }

    this.state = {
      loading: true,
      filterOpen: false,
      filters: {
        Banned: '',
      },
      data: [],
      searchString,
      Department: [],
    };
  }

  loadUser = () => {
    this.setState({
      loading: true,
    });
    api('get', settings.usermanagement.general.getList, {
      Token: localStorage.getItem('token'),
    })
      .then((res) => {
        this.setState({
          data: JSON.parse(res).userList,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.initConstants();
    // const promise = reqwest({
    //   method: 'get',
    //   url: settings.usermanagement.general.getList,
    //   data: {
    //     Token: localStorage.getItem('token'),
    //   },
    // }).then((res) => {
    //   this.setState({
    //     data: JSON.parse(res).userList,
    //   });
    // });
    this.loadUser();

    // Promise.all([this.loadUser]).then(() => {
    //   this.setState({
    //     loading: false,
    //   });
    // });
  }

  async initConstants() {
    this.setState({
      Department: await getConstants('Department'),
    });
  }

  _search = (value) => {
    const val = value.toLowerCase();

    history.replace(val ? `/usermanagement/search/${val}` : '/usermanagement');

    this.setState({
      searchString: val,
    });
  };

  _toggleFilter = () => {
    this.setState((prevState) => {
      return {
        filterOpen: !prevState.filterOpen,
      };
    });
  };

  _changeFilter = (filterName, filterValue) => {
    this.setState((prevState) => {
      return {
        filters: {
          ...prevState.filters,
          [filterName]: filterValue || '',
        },
      };
    });
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    let { data } = this.state;
    if (data.length && this.state.searchString.length) {
      data = data.filter((rowData) => {
        return [
          this.state.Department.find((elem) => {
            return elem.value === rowData.Department;
          })?.label,
          rowData.Lastname,
          rowData.Firstname,
          rowData.Email,
        ]
          .map((e) => {
            return e ? e.toLowerCase() : e;
          })
          .some((v) => {
            return v ? v.indexOf(this.state.searchString) >= 0 : false;
          });
      });

      const filterKeys = Object.keys(this.state.filters);
      filterKeys.forEach((key) => {
        if (this.state.filters[key] !== '') {
          data = data.filter((rowData) => {
            return rowData[key] === this.state.filters[key];
          });
        }
      });
    }

    return (
      <>
        <div className="Headerbar sticky">
          <div className="Headerbar-breadcrumbs">Benutzerverwaltung</div>
          <div className="Headerbar-buttons">
            <HeaderbarButton icon={faRepeat} onClick={this.loadUser}>
              Liste aktualisieren
            </HeaderbarButton>
          </div>
        </div>
        <div className="Page-Content">
          <div className="Buttonbar">
            <Button icon={faPlus} to="/usermanagement/new">
              Neue Benutzer anlegen
            </Button>
          </div>
          <div className="Toolbar">
            <Searchbar
              placeholder="Benutzer durchsuchen..."
              defaultValue={this.state.searchString}
              onChange={this._search}
            />
            <Button icon={faFilter} onClick={this._toggleFilter}>
              Filter
            </Button>
            <div className="Toolbar-buttons"></div>
          </div>
          {this.state.filterOpen && (
            <div className="Toolbar-Filter">
              <SearchDropdown
                label="Benutzer gesperrt:"
                options={[
                  {
                    value: 'true',
                    label: 'gesperrt',
                  },
                  {
                    value: 'false',
                    label: 'nicht gesperrt',
                  },
                ]}
                defaultValue={this.state.filters.Banned}
                onChange={(value) => {
                  this._changeFilter('Banned', value);
                }}
              />
            </div>
          )}
          <ReactTable
            getTrProps={(_, val) => {
              return {
                onClick: () => {
                  return this.props.history.push(
                    `/usermanagement/${val?.original?.Guid}`,
                  );
                },
              };
            }}
            data={data}
            pageSize={data.length}
            noDataText="Es wurden keine Benutzer gefunden."
            columns={[
              {
                accessor: 'dienststelle',
                Header: 'Dienststelle',
                Cell: ({ original }) => {
                  return (
                    <>
                      {
                        this.state.Department.find((elem) => {
                          return elem.value === original.Department;
                        })?.label
                      }
                    </>
                  );
                },
              },
              {
                accessor: 'name',
                Header: 'Name',
                Cell: ({ original }) => {
                  return <>{original.Lastname}</>;
                },
              },
              {
                accessor: 'firstname',
                Header: 'Vorname',
                Cell: ({ original }) => {
                  return <>{original.Firstname}</>;
                },
              },
              {
                accessor: 'address',
                Header: 'Mail',
                Cell: ({ original }) => {
                  return (
                    <>
                      <FontAwesomeIcon fixedWidth icon={faEnvelopeOpenText} />{' '}
                      {original.Email}
                    </>
                  );
                },
              },
              {
                accessor: 'Banned',
                Header: 'Status',
                sortMethod: (a, b) => {
                  if (a === b) {
                    return 0;
                  }
                  if (a === false || a === 'false') {
                    return 1;
                  }
                  if (b === false || b === 'false') {
                    return -1;
                  }
                  return 0;
                },
                Cell: ({ original }) => {
                  return (
                    <>
                      {original.Banned === ''
                        ? ''
                        : original.Banned === false ||
                          original.Banned === 'false'
                        ? 'Aktive'
                        : 'Gesperrt'}
                    </>
                  );
                },
              },
            ]}
          />
        </div>
      </>
    );
  }
}

export default Usermanagement;
